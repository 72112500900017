import tree from './course-wizard-tree.json';

export default function CourseWizard() {
    return {
        currentState: {},
        initialState: {},
        stateHistory: [],
        init() {
            this.initialState = this.findStateById(tree.id);
            this.currentState = this.initialState;
            this.stateHistory = [this.initialState.id];
        },
        findStateById(stateId) {
            return tree.states.find((state) => state.id === stateId);
        },
        updateState(nextStateId) {
            if (typeof nextStateId !== 'number') {
                console.error(`Invalid nextStateId: ${nextStateId}`);
                return;
            }
            const nextState = this.findStateById(nextStateId);
            if (nextState) {
                this.currentState = nextState;
                this.stateHistory.push(nextState.id);
            } else {
                console.error(`Next state not found: ${nextStateId}`);
            }
        },
        startOver() {
            this.currentState = this.initialState;
            this.stateHistory = [this.initialState.id];
        },
        goBack() {
            if (this.stateHistory.length > 1) {
                this.stateHistory.pop();
                const previousStateId =
                    this.stateHistory[this.stateHistory.length - 1];
                this.currentState = this.findStateById(previousStateId);
            } else {
                console.error('Already at the beginning of the wizard.');
            }
        },
    };
}
